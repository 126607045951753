import axios from '@/plugins/axios'

export default {
  async getProfile() {
    return axios.get(`/seller/me`)
  },
  async verify(id, hash, query) {
    return axios.get(`/seller/merchant-email/verify/${id}/${hash}?${query}`)
  },
  async sendVerify() {
    return axios.post(`/seller/merchant-email/verification-notification`)
  },
  async getMerchant() {
    return axios.get(`/seller/merchant`)
  },
  async postMerchant(payload) {
    return axios.post(`/seller/merchant`, payload)
  },
  async putMerchant(payload) {
    return axios.put(`/seller/merchant`, payload)
  },
  async putStatusMerchant(payload) {
    return axios.post(`/seller/merchant/status`, payload)
  },
  // async postMerchantNote(payload) {
  //   return axios.post(`/seller/merchants/note`, payload)
  // },
  // async putMerchantNote(payload) {
  //   return axios.put(`/seller/merchants/note/${payload.id}`, payload)
  // },
  // async deleteMerchantNote(id, payload) {
  //   return axios.delete(`/seller/merchants/note/${id}`, payload)
  // },
  async getUsers(query = '') {
    return axios.get(`/seller/merchant/users/${query}`)
  },
  async addNewUser(payload) {
    return axios.post(`/seller/merchant/users`, payload)
  },
  async putRoleUser(userId, payload) {
    return axios.post(`/seller/merchant/${userId}/update-roles`, payload)
  },
  async putStatusUser(userId, payload) {
    return axios.post(`/seller/merchant/${userId}/update-active`, payload)
  },
  async LogActivities(query = '') {
    return axios.get(`/seller/merchant/log/activities${query}`)
  },
  async getAffiliateCode(payload) {
    return axios.post(`/seller/check-branch`, payload)
  }
}