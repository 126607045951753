import axios from '@/plugins/axios'

export default {
  async refreshNotifications() {
    return axios.get(`/seller/notifications/refresh`)
  },
  async getNotifications(query) {
    return axios.get(`/seller/notifications${query}`)
  },
  async readNotification(id) {
    return axios.get(`/seller/notifications/${id}/read`)
  },
  async readAllNotification() {
    return axios.get(`/seller/notifications/read-all`)
  },
  async preferences() {
    return axios.get(`/seller/notifications/preferences`)
  },
  async updatePreferences(payload) {
    return axios.put(`/seller/notifications/preferences`, payload)
  },
}