<template>
  <div id="app">
    <RouterView />
    <Notify />
  </div>
</template>

<script>
import Notify from "./components/notification/Response.vue";

export default {
  components: {
    Notify,
  },
};
</script>

<style>
</style>
