import axios from '@/plugins/axios'

export default {
  // product
  async getProducts(query = '') {
    return axios.get(`/seller/products${query}`)
  },
  async getProductById(payload) {
    return axios.get(`/seller/products/${payload}`)
  },
  async getCountProductAvailable() {
    return axios.get(`/seller/products/item/total`)
  },
  async getProductsUploaded(query = '') {
    return axios.get(`/seller/products/upload/index${query}`)
  },
  async saveProduct(payload) {
    return axios.post(`/seller/products`, payload)
  },
  async updateProduct(id, payload) {
    return axios.put(`/seller/products/${id}`, payload)
  },
  async updateStatusProduk(id, payload) {
    return axios.put(`/seller/products/${id}/status`, payload)
  },
  async saveProductByDocument(payload) {
    let data = new FormData()
    data.append('file', payload.file)
    data.append('type', payload.type)

    return axios.post(`/seller/products/document/import`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  // product storages
  async getMerchantStorage() {
    return axios.get(`/seller/merchant-storages`)
  },
  async postMerchantStorage(payload) {
    return axios.post(`/seller/merchant-storages`, payload)
  },
  async putMerchantStorage(payload) {
    return axios.put(`/seller/merchant-storages/${payload.id}`, payload)
  },
  async deleteMerchantStorage(id) {
    return axios.delete(`/seller/merchant-storages/${id}`)
  },

  // product books
  async getNonBookSchoolLevel() {
    return axios.get(`/seller/products/book/non-text-book/school-levels`)
  },
  async getNonTextBooks(id, param) {
    return axios.get(`/seller/products/book/non-text-book/search/${id}?publisher=${param}`)
  },
  async getBookPublishers() {
    return axios.get(`/seller/products/book/publisher`)
  },
  async getTextBookById(id) {
    return axios.get(`/seller/products/book/text-book/${id}`)
  },
  async getNonTextBookById(id) {
    return axios.get(`/seller/products/book/non-text-book/${id}`)
  },
  async getSpecifikBooks(query = "") {
    return axios.get(`/seller/products/book/search${query}`)
  },
  async getBookById(id) {
    return axios.get(`/seller/products/book/${id}`)
  },

  // discuss & review product
  async getDiscussByProduct(productId, query = "") {
    return axios.get(`/seller/products/${productId}/discussion${query}`)
  },
  async getProductDiscussions(query = "") {
    return axios.get(`/seller/discussions${query}`)
  },
  async replyProductDiscussions(id, payload) {
    return axios.post(`/seller/discussions/${id}/discussion-reply`, payload)
  },
  async getReviewsProduct(productId) {
    return axios.get(`/seller/products/${productId}/reviews`)
  },

  // product packages
  async getProductPackages(query = '') {
    return axios.get(`/seller/product-packages${query}`)
  },
  async getProductPackagesById(id) {
    return axios.get(`/seller/product-packages/${id}`)
  },
  async postProductPackages(payload) {
    return axios.post(`/seller/product-packages`, payload)
  },
  async putProductPackages(id, payload) {
    return axios.put(`/seller/product-packages/${id}`, payload)
  },
  async deleteProductPackages(id) {
    return axios.delete(`/seller/product-packages/${id}`)
  },
}