import axios from '@/plugins/axios'

export default {
    async getOrders(query = '') {
        return axios.get(`/seller/orders${query}`)
    },
    async getOrderDetail(payload) {
        return axios.get(`/seller/orders/${payload}`)
    },
    async cancelingOrder(id, payload) {
        return axios.post(`/seller/orders/${id}/process-canceling`, payload)
    },
    async processOrder(id, payload) {
        return axios.post(`/seller/orders/${id}/process`, payload)
    },
    async readyToPickup(id) {
        return axios.post(`/seller/orders/${id}/pickup`)
    },
    async sendOrder(id, payload) {
        return axios.post(`/seller/orders/${id}/send`, payload)
    },
    async updateResi(id, payload) {
        return axios.put(`/seller/orders/${id}/resi`, payload)
    },
    async rejectOrder(id, payload) {
        return axios.post(`/seller/orders/${id}/reject`, payload)
    },
    async updatingOrder(id, payload) {
        return axios.post(`/seller/orders/${id}/process-updating`, payload)
    },
    async returningOrder(id, payload) {
        return axios.post(`/seller/orders/${id}/process-returning`, payload)
    },
    async fileClosing(id) {
        return axios.get(`/seller/orders/${id}/closing`)
    },
    async closingOrder(id, payload) {
        return axios.post(`/seller/orders/${id}/closing`, payload, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
    },
    async checkVa(payload) {
        return axios.post(`/seller/orders/check-va`, payload)
    },
    async getNegotiations(query = '') {
        return axios.get(`/seller/negotiations${query}`)
    },
    async getNegotiationDetail(id) {
        return axios.get(`/seller/negotiations/${id}/show`)
    },
    async acceptNego(payload) {
        return axios.post(`/seller/negotiations/accept`, payload)
    },
    async replyNego(payload) {
        return axios.post(`/seller/negotiations/reply`, payload)
    },
    async rejectNego(payload) {
        return axios.post(`/seller/negotiations/reject`, payload)
    },
    async getBulkNego(query = '') {
        return axios.get(`/seller/negotiations/bulk${query}`)
    },
    async replyBulkNego(payload) {
        return axios.post(`/seller/negotiations/bulk-reply`, payload)
    },

    async orderHistories(id) {
        return axios.get(`/seller/orders/${id}/log`)
    },
    async uploadTaxInvoice(id, payload) {
      let data = new FormData()
      data.append('tax_invoice_file', payload)

      return axios.post(`/seller/orders/${id}/tax-invoice`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    },
    async removeTaxInvoice(id) {
      return axios.delete(`/seller/orders/${id}/tax-invoice`)
    },
}