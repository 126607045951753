import { auth, profile } from '@/services'
import { intersection } from 'lodash'

// state
export const state = {
  user: null,
  merchant: null,
  popupNotif: false,
  bankPremium: [
    'BANK JATIM',
    'BNI 46',
    'DKI',
    'BANK BJB',
    'BRI',
    'JATENG',
    'B. NAGARI',
    'BPD BALI',
    'MANDIRI',
    'BANK SYARIAH INDONESIA',
    'BCA',
  ]
}

// getters
export const getters = {
  user: state => state.user,
  merchant: state => state.merchant,
  merchantInvalid: state => state.merchant && Object.keys(state.merchant.errors ?? {}).length,
  check: state => state.user !== null,
  userEmailVerified: state => state.user && state.user.email_verified_at !== null,
  merchantEmailVerified: state => state.merchant && state.merchant.email_verified_at !== null,
  hasMerchant: state => state.user && state.user.merchant_id !== null,
  merchantVerify: state => state.user && state.merchant && state.merchant.status == 'verify',
  merchantPremium: state => state.user && state.merchant && state.merchant.premium_type > 0,
  canRegisterPremium: state => state.user && state.merchant && state.bankPremium.includes(state.merchant.bank_account['bank']),
  hasRole: (state) => role => {
    if (!state.user?.enabled) return false
    let isSuper = state.user.roles.includes('*')
    let roles = Array.isArray(role) ? role : [role]
    let isAllowed = intersection(state.user.roles, roles).length > 0
    return isSuper || isAllowed
  },
  showPopupNotif: state => state.popupNotif,
  bankPremiumOptions: state => state.bankPremium.join(', '),
}

// mutations
export const mutations = {
  SET_USER(state, payload) {
    state.user = payload
  },
  SET_MERCHANT(state, payload) {
    state.merchant = payload
  },
  SET_POPUP_NOTIF(state, payload) {
    state.popupNotif = payload
  },
}

// actions
export const actions = {
  async fetchUser({ commit, state, dispatch }) {
    if (state.user) return
    try {
      const response = await auth.check()
      if (response.status === 200) {
        commit('SET_USER', response.data)
        await dispatch('fetchMerchant', true)
      }
    } catch (error) {
      commit('SET_USER', null)
    }
  },
  async fetchMerchant({ commit, state }, force = false) {
    if (!state.user) return;
    if (!state.user.merchant_id) return;
    if (!force) {
      if (state.merchant) return;
    }

    try {
      const response = await profile.getMerchant()

      if (response.status === 200) {
        commit('SET_MERCHANT', response.data)
      } else {
        commit('SET_MERCHANT', null)
      }
    } catch (error) {
      commit('SET_MERCHANT', null)
    }
  },
  async login({ commit, dispatch }, payload) {
    try {
      const response = await auth.login(payload)
      if (response) {
        commit('SET_USER', response.data)
        await dispatch('fetchMerchant', true)
        return response
      }
    } catch (error) {
      commit('SET_USER', null)
    }

  },
  async register({ commit }, payload) {
    try {
      const response = await auth.register(payload)
      if (response) {
        commit('SET_USER', response.data)
        return response
      }
    } catch (error) {
      commit('SET_USER', null)
    }

  },
  setUser({ commit }, payload) {
    commit('SET_USER', payload)
  },
  async logout({ commit }) {
    try {
      await auth.logout()

      commit('SET_USER', null)
      commit('SET_MERCHANT', null)
    } catch (error) {
    }
  }
}
