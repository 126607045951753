import axios from '@/plugins/axios'

export default {
  async getComplains(query = '') {
    return axios.get(`/seller/complains${query}`)
  },
  async getComplainById(payload) {
    return axios.get(`/seller/complains/${payload}`)
  },
  async getComplainByOrder(orderId) {
    return axios.get(`/seller/complains/${orderId}/details`)
  },
  async postComplaintByOrder(orderId, payload) {
    return axios.post(`/seller/complains/${orderId}`, payload)
  },
  async reply(id, payload) {
    return axios.post(`/seller/complains/${id}/reply`, payload)
  },
  async close(id, payload) {
    return axios.post(`/seller/complains/${id}/close`, payload)
  }
}