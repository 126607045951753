
// state
export const state = {
  taxed: 0.11,
  isLoading: false,
  imgUserDefault: require('@/assets/images/logo.svg')
}

// getters
export const getters = {}

// mutations
export const mutations = {
  setLoading(state, payload) {
    state.isLoading = payload
  }
}

// actions
export const actions = {}
