let defaults = {
  time: 5000,
  delay: 200,
  text: '',
  type: null
}

// state
export const state = {
  sidebarResponsive: false,
  showModal: false,
  notify: [],
}

// getters
export const getters = {
  notify: state => state.notify,
}

// mutations
export const mutations = {
  SET_SIDEBAR(state, params) {
    state.sidebarResponsive = params
  },
  SET_NOTIFY(state, payload) {
    if (state.notify.length > 1) state.notify.splice(0, 1)
    state.notify.push(payload)
  },
  CLOSE_NOTIF(state, index) {
    state.notify.splice(index, 1)
  },
  SET_CONFIRM(state, payload) {
    state.showModal = payload
  },
}

// actions
export const actions = {
  notify({ commit }, payload) {
    payload = { ...defaults, ...payload }
    setTimeout(() => {
      commit('SET_NOTIFY', payload)

      setTimeout(() => {
        commit('CLOSE_NOTIF', 0)
      }, payload.time)
    }, payload.delay)
  },
  setSidebar({ commit }, params) {
    commit('SET_SIDEBAR', params)
  },
}
