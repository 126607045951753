import axios from '@/plugins/axios'

export default {
  async maintenance() {
    return axios.get('/ping')
  },
  async getPpnTagItems() {
    return axios.get(`/public/ppn/items`)
  },
  async getCategories(query) {
    return axios.get(`/public/product-categories${query}`)
  },
  async getCategoryById(id) {
    return axios.get(`/public/product-categories/${id}`)
  },
  async getBanks() {
    return axios.get(`/public/banks`)
  },
  async getSystemBanks() {
    return axios.get(`/public/system-banks`)
  },
  async getPaymentMethods(query = '?active=0') {
    return axios.get(`/public/payment-methods${query}`)
  },
  async getProvinces() {
    return axios.get(`/public/provinces`)
  },
  async getCities(params) {
    return axios.get(`/public/cities/${params}`)
  },
  async getDistricts(params) {
    return axios.get(`/public/districts/${params}`)
  },
  async getVillages(params) {
    return axios.get(`/public/village/${params}`)
  },
  async reviews(merchantId, query = '') {
    return axios.get(`/public/merchants/${merchantId}/reviews${query}`)
  },
  async uploadFiles(file, type) {
    let data = new FormData()
    data.append('file', file)
    data.append('type', type)


    return axios.post(`/seller/upload`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
}