import axios from '@/plugins/axios'

export default {
  async check() {
    return axios.get(`/seller/check`)
  },
  async register(params) {
    await axios.get(`public/csrf-cookie`)
    return axios.post(`/seller/register`, params)
  },
  async login(params) {
    await axios.get(`public/csrf-cookie`)
    return axios.post(`/seller/login`, params)
  },
  async logout() {
    return axios.post(`/seller/logout`)
  },
  async forgotPassword(payload) {
    return axios.post(`/seller/password/email`, payload)
  },
  async resetPassword(payload) {
    return axios.post(`/seller/password/reset`, payload)
  },
  async sendVerify() {
    return axios.post(`/seller/seller-email/verification-notification`)
  },
  async verify(id, hash, query) {
    return axios.get(`/seller/seller-email/verify/${id}/${hash}?${query}`)
  }
}

