import axios from '@/plugins/axios'

export default {
    async index(query = '') {
        return axios.get(`/seller/premium${query}`)
    },
    async register(payload) {
        return axios.post(`/seller/premium`, payload)
    },
    async pay(payload) {
        return axios.post(`/seller/premium/pay`, payload)
    },
    async cancel(payload) {
        return axios.post(`/seller/premium/cancel`, payload)
    },
    async products(payload) {
        return axios.get(`/seller/premium/products`, payload)
    }
}
